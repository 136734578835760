.signin-bg {
    width: 100%;
    height: 100%;
    /* background-image: url("../../assets/user-profile/summer.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.signin-bg-2 {
    height: 100%;
    background: rgba(20, 29, 3, 0.144);
}

.signin-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signin {
    position: relative;
    border-radius: 4px;
    width: 100%;
    /* height: 100%; */
    padding: 0;
    background: #fff;
}

.signin-content {
    display: grid;
    gap: 24px;
    padding: 32px 16px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    max-width: 511px;
}

.close-btn-box {
    position: absolute;
    top: 0;
    right: 0;
}

.close-btn {
    align-items: center;
    background: transparent;
    border: none;
    color: rgb(42, 115, 204);
    cursor: pointer;
    display: inline-flex;
    font-size: inherit;
    height: 50px;
    justify-content: center;
    padding: 0px;
    transition: background-color 250ms ease-out 0s, box-shadow 250ms ease-out 0s;
    width: 50px;
}

.signin-head {
    display: block;
}

.signin-head h1 {
    color: rgb(31, 31, 31);
    font-family: Roboto;
    font-size: 1.125rem;
    line-height: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.signin-foot {
    display: block;
}

.signin-foot p:first-child {
    color: rgb(97, 97, 97);
    font-size: 12px;
    line-height: 20px;
    padding: 0px 32px;
    text-align: center;
}

.signin-foot p:nth-child(2) {
    color: rgb(97, 97, 97);
    font-size: 12px;
    line-height: 20px;
    margin: 16px 0px 0px;
    padding: 0px 32px;
    text-align: center;
}

.signin-foot a {
    color: inherit;
    text-decoration: underline;
    color: rgb(97, 97, 97);
    font-size: 12px;
    line-height: 20px;
}

.signin-body {
    display: block;
}

.login-form {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr;
    /* margin: 0 0 21px; */
}

.password-pd {
    padding-bottom: 26px;
}

.login-field {
    display: block;
    position: relative;
}   

.login-field label {
    color: rgb(31, 31, 31);
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 600;
}

.login-field div:nth-child(2) {
    background: rgb(255, 255, 255);
    display: flex;
    border-radius: 2px;
    border: 1px solid rgb(189, 189, 189);
    font-size: 16px;
    line-height: 22px;
    transition: border-color 250ms ease-out 0s;
}

.login-field div:nth-child(2) input {
    height: unset;
    padding: 12px 16px;
    line-height: 22px;
    font-size: 16px;
    outline: none;
    color: unset;
    border: 0px !important;
    margin-bottom: 0px !important;
    box-shadow: none !important;
    width: 100%;
    background: #D9D9D9!important;
    gap: 1rem;
    
}

.capcha-sec {
    position: relative;
}

.capcha-sec label {
    color: rgb(31, 31, 31);
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 600;
}

.capcha-sec div:nth-child(2) {
    /* background: rgb(255, 255, 255);
    display: flex;
    border-radius: 2px;
    font-size: 16px;
    line-height: 22px;
    transition: border-color 250ms ease-out 0s; */
}

.capcha-sec div:nth-child(2) input {
    height: unset;
    padding: 12px 16px;
    line-height: 22px;
    font-size: 16px;
    outline: none;
    color: unset;
    border: 1px solid rgb(189, 189, 189)!important;
    margin-bottom: 0px !important;
    box-shadow: none !important;
    width: 100%;
    background: #D9D9D9!important;
    border-radius: 2px;
}

.capcha-row {
    display: flex;
    border: unset!important;
    align-items: center;
}

#sign-up .capcha {
    line-height: 46px;
}

.login-field div:nth-child(2) button {
    padding: 0;
}

.login-field div:nth-child(3) {
    bottom: 0px;
    color: rgb(97, 97, 97);
    font-size: 12px;
    left: 0px;
    line-height: 18px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
}

.login-field div:nth-child(3) button {
    background: transparent;
    border: none;
    color: rgb(42, 115, 204);
    cursor: pointer;
    display: inline;
    font-size: inherit;
    padding: 0px;
    transition: color 250ms ease-out 0s;
}

.invisible-line {
    width: 100%;
}

.login-btn {
    background: #C49A6C;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    transition: background-color 250ms ease-out 0s, box-shadow 250ms ease-out 0s, color 250ms ease-out 0s;
    color: rgb(255, 255, 255);
    padding: 12px 32px;
    margin-top: 12px;
    cursor: pointer;
}

.login-btn:hover {
    background: #a7825b;
}

.register {
    margin-top: 12px;
    text-align: center;
}
 
.register button {
    margin-left: 8px;
    padding: 0;
}

.register button span {
    color: #b44820;
}

.or-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.or-line div {
    background-color: rgb(255, 255, 255);
    color: rgb(97, 97, 97);
    font-size: 12px;
    line-height: 18px;
    padding: 0px 10px;
}

.or-line hr {
    margin: 24px 0px;
    height: 0.5px;
    border: none;
    background-color: rgb(225, 225, 225);
    flex: 1 1 0%;
}

.other-options {
    display: grid;
    gap: 16px;
}

.options-icon {
    fill: rgb(54, 59, 66);
    height: 18px;
    width: 18px;
    left: 24px;
    position: absolute;
    top: 15px;
}

.fb {
    fill: rgb(59, 89, 152);
}

.apple {
    fill: rgb(54, 59, 66);
}

.other-options button{
    background: transparent;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    transition: background-color 250ms ease-out 0s, box-shadow 250ms ease-out 0s, color 250ms ease-out 0s;
    padding: 12px 16px;
    border: 1px solid rgb(31, 31, 31);
    color: rgb(31, 31, 31);
    position: relative;
    width: 100%;
    cursor: pointer;
}

.other-options button:hover {
    background-color: rgba(218, 246, 250, 0.425);
}

.new-user {
    margin: 16px 0px 0px;
    text-align: center;
    font-size: 14px;
}

.new-user button {
    background: transparent;
    border: none;
    color: rgb(42, 115, 204);
    cursor: pointer;
    display: inline;
    font-size: inherit;
    padding: 0px;
    transition: color 250ms ease-out 0s;
    text-decoration: underline;
}

.line {
    height: 0.5px;
    border: none;
    background-color: rgb(225, 225, 225);
    margin: 24px 0px;
}

.org-login {
    margin: 0px;
    text-align: center;
}

.org-login button {
    background: transparent;
    border: none;
    color: rgb(42, 115, 204);
    cursor: pointer;
    display: inline;
    font-size: inherit;
    padding: 0px;
    transition: color 250ms ease-out 0s;
    font-size: 14px;
}

.notify-modal p {
    text-align: center;
}

.notify-title {
    text-transform: uppercase;
}

.sign-up {
    background: #fff;
    width: 1081px;
    height: 699px;
    padding: 2rem 5rem;
    border-radius: 10px;
}

.sign-up-title {
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;
}

#sign-up .user-info-row input {
    background: #D9D9D9;
    border: 1px solid rgb(202, 202, 202)!important;
    border: unset;
    padding: 10px 16px;
}

.capcha-row {
    flex-grow: 1;
    display: flex;
    width: 100%;
    gap: 1.5rem;
}

.hide {
    opacity: 0;
}

.modal-title {
    text-transform: uppercase;
    text-align: center;
}

@media screen and (min-width: 600px) {
    .signin {
        border: 1px solid rgb(11, 153, 189);
        width: 511px;
        min-height: 688px;
        padding: 16px 24px;
    }
    .signin-content {
        padding: 12px 16px;
    }

    .signin-head h1 {
        font-size: 1.25rem;
        line-height: 2.5rem;
    }
}
