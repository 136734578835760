.ads_wrapper_setting{
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px 0px;
}
.titlebox_ads{
    background-color: white;
    padding: 15px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.info_ads_setting{
    width: 100%;
    height: fit-content;
    padding: 0px 10px;
}
/* .titlebox_ads{
    color: #2C3D83;
    font-size: large;
    background-color: white;
    border-radius: 8px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center ;
} */
.title{ 
        display: flex;
        color: #2C3D83;
        justify-content: space-between;
}
.title-content{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.content_ads{
    background-color: white;
    margin: 10px 0px 10px 0px;
    border-radius: 8px;  
    padding: 20px 20px;
    width: 100%;
}
.form_select{
  display: flex;
  width: 100%
}