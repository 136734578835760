// style of folder

.folder-list {
    .folder--selected {
        background-color: blue;
        color: white;
        .folder__icon {
            color: white;
        }
    }
}

// style of images
.image-list {
    display: grid;
    grid-template-columns: 19.2% 19.2% 19.2% 19.2% 19.2%;
    gap: 1%;
    margin-top: 12px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
    .image--checked {
        .image-inner {
            // overflow: hidden;
            border: 2px solid blue;
            position: relative;
            .tickmark {
                position: absolute;
                top: 0;
                left: 0;
                border: 20px solid;
                z-index: 1;
                border-color: #1677ff transparent transparent #1677ff;
            }
            .tickmark::before {
                content: "";
                position: absolute;
                top: -18px;
                left: -18px;
                width: 15px;
                height: 5px;
                border: 3px solid;
                border-color: transparent transparent #fff #fff;
                transform: rotate(-45deg);
            }
        }
    }
}

// style of detail-infomation

.details-info {
    background-color: #f5f5f5;
    .details__header {
        background-color: #e6e6e6;
        font-size: 16px;
        padding: 8px;
        margin: 0px -16px;
    }
    .details__body {
        .info {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 200px;
            img {
                width: 60%;
                height: 80%;
                object-fit: cover;
            }
            &__name {
                font-weight: bold;
            }
        }
        .info {
            text-align: center;
        }
    }
}

.epr-cms-image-section-container {
    width: 60px;
    display: flex;
    flex-direction: column;
}
.epr-cms-image-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* This is where the magic happens. */

}
.selected-file {
    line-height: normal;
    display: flex;
    flex-direction: column;

    span {
        font-weight: bold;
    }
}