.signin-container {
  margin: 10px auto 10px auto; 
}

#components-form-demo-normal-login .login-form {
    margin: 0 auto; 
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;
  }