.about_wrapper_setting{
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px 0px;
}
.info_about_setting{
    width: 100%;
    height: fit-content;
    padding: 0px 10px;
}
.titlebox_about{
    color: #2C3D83;
    font-size: large;
    background-color: white;
    border-radius: 8px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center ;
}
.title-content{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.content_about{
    background-color: white;
    margin: 10px 0px 10px 0px;
    border-radius: 8px;  
    padding: 20px 20px;
    width: 100%;
}
.form_select{
  display: flex;
  width: 100%
}