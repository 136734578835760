.menuSider{
    width: 100;
    height: 100px;
}
.sider {
    overflow: auto;
    align-Items: center;
    position: sticky;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0
}