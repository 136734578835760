.wrapper{
    display: inline-block;
    padding: 20px 20px 20px 20px;
    width: 100%;
}
.titleBox{
    background-color: white;
    padding: 15px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.title{ 
    display: flex;
    color: #2C3D83;
    font-size: large;
}
.left-content{
    background-color: white;
    width: 75%;
}
.right-content{
    background-color: white;
    width: 25%;
}
.option-box{
    display: flex;
    /* flex-wrap: wrap; */
}
.wrapper-content{
    display: flex;
    flex-direction: row;
}
.main-content{ 
    /* background-color: white; */
    margin-top: 20px;
    /* padding: 10px 10px; */
    display: flex;
    flex-direction: column;
    width: 100%;
}
.post-content { 
    background-color: white;
    border-radius: 8px;
    padding: 10px 10px; 
    width: 100%;
    margin-top:20px ;
}

.title-post-content{ 
    color: #2C3D83;
    font-size: large;
    float: left;
}
.post-option-wrapper{
    width: 25%;
    margin-top: 20px;
    margin-left: 20px;
}
.control-hooks{
    width: 100%;
}
.post-option{ 
    background-color: white;
    border-radius: 8px;
    padding: 10px 10px; 
}
.option-element{
    display: flex;
    flex-direction: column;
}
.option-banner{
     float: left;
}
.option-element-title{
    float: left;
    font-weight: bold;
}
.option-radio{
    margin-top: 10px;
    margin-bottom: 10px;
}
.datepicker{
    width: 100%;
    float: left;
}