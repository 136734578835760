.info_device_wrapper{
    display: flex;
    flex-direction: column;
}
.info_device_content{
    width: 100%;
    height: fit-content;
    margin: 20px 20px;
    background-color: white;
    border-radius: 8px;
    padding: 24px 10px;
}
.titlebox_device{
    text-align: left;
    color: #2C3D83;
    font-size: large;
}
.title-content{
    display: flex;
    flex-direction: column;
    text-align: left;
}
