.header {
    display: flex;
    align-Items: center;
    justify-Content: space-between;
    background-Color: #2C3D83;
    position: sticky;
    z-index: 1;
    top: 0
}
.menu{
    width: 75%;
    font-size: 16px;
    background-color:#2C3D83 ;
    color: white
}